const mutations = {
	startOrderProcess: 'MUTATION_START_ORDER_PROCESS',
	stopOrderProcess: 'MUTATION_STOP_ORDER_PROCESS',
	voucherTemplatesUpdated: 'MUTATION_VOUCHER_TEMPLATES_UPDATED',
	voucherAmountsUpdated: 'MUTATION_VOUCHER_AMOUNTS_UPDATED',
	voucherCompleted: 'MUTATION_VOUCHER_SELECTED',
	invoiceCompleted: 'MUTATION_INVOICE_COMPLETED',
	orderPlaced: 'MUTATION_ORDER_PLACED',
	paymentInitialized: 'MUTATION_PAYMENT_INITIALIZED',
	paymentCompleted: 'MUTATION_PAYMENT_COMPLETED',
	login: 'MUTATION_LOGIN',
	logout: 'MUTATION_LOGOUT',
	invalidateToken: 'MUTATION_INVALIDATE_TOKEN',
	vouchersUpdated: 'MUTATION_VOUCHERS_UPDATED',
	singleVoucherUpdated: 'MUTATION_SINGLE_VOUCHER_UPDATED',
	voucherDeleted: 'MUTATION_VOUCHER_DELETED',
	resetState: 'MUTATION_RESET_STATE'
};

export default mutations;