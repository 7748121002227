
	import {Component, Vue} from 'vue-property-decorator';
	import mutations from '../mutations';

	@Component({
		name: 'Home'
	})
	export default class Home extends Vue
	{
		public clicked()
		{
			this.$store.commit(mutations.resetState);
		}
	}
