import axios from 'axios';
import store from '@/store';
import mutations from "@/mutations";
import {or} from "vuelidate/lib/validators";

class PaymentService
{
	public async initializePayment()
	{
		try
		{
			let paymentPage = window.open('', '', 'width=800,height=600');

			let response = await axios.post(process.env.VUE_APP_GENERATOR_API_ROOT + 'payment/initialize', {
				orderId: store.state.orderId
			});


			if (!response.data.success || !response.data.redirectUrl)
			{
				throw new Error('Payment could not be initialized!');
			}

			if (response.data.success && response.data.redirectUrl && response.data.paymentToken)
			{
				paymentPage.location.href = response.data.redirectUrl;

				store.commit(mutations.paymentInitialized, response.data.paymentToken);
			}
			else
			{
				paymentPage.close();

				throw new Error('Response incorrect!');
			}
		}
		catch (e: any)
		{
			throw new Error(e);
		}
	}

	public async getPaymentStatus(orderId?: number)
	{
		return await axios.post(process.env.VUE_APP_GENERATOR_API_ROOT + 'payment/get-status', {
			orderId: orderId ? orderId : store.state.orderId
		});
	}
}

export default PaymentService;