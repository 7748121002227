
	import {Vue, Component} from 'vue-property-decorator';
	import {mapGetters} from 'vuex';
	import OrderData from "../entities/OrderData";
	import {PAYMENT_TYPES} from "../enums";

	@Component({
		name: 'Sidebar',
		computed: mapGetters([
			'voucherCompleted',
			'invoiceCompleted',
			'orderPlaced',
			'orderData',
			'paymentInitialized',
			'paymentCompleted'
		])
	})
	export default class Sidebar extends Vue
	{
		private PAYMENT_TYPES: object = PAYMENT_TYPES;
		private voucherCompleted: boolean;
		private invoiceCompleted: boolean;
		private orderPlaced: boolean;
		private orderData: OrderData;
		private paymentInitialized: boolean;
		private paymentCompleted: boolean;
	}
