import axios from 'axios';
import store from '@/store';
import router from '@/router';
import mutations from "@/mutations";

class AdminService
{
	public uploadVoucherTemplate(formData: FormData)
	{
		//append api token
		formData.append('apiToken', store.state.apiToken);

		return axios.post(process.env.VUE_APP_GENERATOR_API_ROOT + 'voucher/templates/upload', formData, {
			headers: {
				Authorization: 'Token ' + store.state.apiToken
			}
		});
	}

	public deleteVoucherTemplate(templateId: number)
	{
		return axios.post(process.env.VUE_APP_GENERATOR_API_ROOT + 'voucher/templates/delete', {
			templateId: templateId
		}, {
			headers: {
				Authorization: 'Token ' + store.state.apiToken
			}
		});
	}

	public checkApiToken()
	{
		axios.post(process.env.VUE_APP_GENERATOR_API_ROOT + 'check-api-token', {
			apiToken: store.state.apiToken
		}).then((response) => {
			if (response.data.success)
			{
				if (!response.data.tokenValid)
				{
					store.commit(mutations.invalidateToken);

					router.push('/admin/login');
				}
			}
		});
	}

	public getVouchers()
	{
		axios.get(process.env.VUE_APP_GENERATOR_API_ROOT + 'vouchers', {
			headers: {
				Authorization: 'Token ' + store.state.apiToken
			}
		}).then((response) => {
			if (response.data.success && response.data.vouchers)
			{
				store.commit(mutations.vouchersUpdated, response.data.vouchers);
			}
		});
	}

	public redeemVoucher(voucherId: number)
	{
		axios.post(process.env.VUE_APP_GENERATOR_API_ROOT + 'vouchers/redeem', {
			voucherId: voucherId
		}, {
			headers: {
				Authorization: 'Token ' + store.state.apiToken
			}
		}).then((response) => {
			if (response.data.success && response.data.voucher)
			{
				store.commit(mutations.singleVoucherUpdated, response.data.voucher);
			}
		});
	}

	public reactivateVoucher(voucherId: number)
	{
		axios.post(process.env.VUE_APP_GENERATOR_API_ROOT + 'vouchers/reactivate', {
			voucherId: voucherId
		}, {
			headers: {
				Authorization: 'Token ' + store.state.apiToken
			}
		}).then((response) => {
			if (response.data.success && response.data.voucher)
			{
				store.commit(mutations.singleVoucherUpdated, response.data.voucher);
			}
		})
	}

	public setVoucherPaymentStatus(voucherId: number, paid: boolean)
	{
		axios.post(process.env.VUE_APP_GENERATOR_API_ROOT + 'vouchers/set-payment-status', {
			voucherId: voucherId,
			paid: paid
		}, {
			headers: {
				Authorization: 'Token ' + store.state.apiToken
			}
		}).then((response) => {
			if (response.data.success && response.data.voucher)
			{
				store.commit(mutations.singleVoucherUpdated, response.data.voucher);
			}
		});
	}

	public deleteVoucher(voucherId: number)
	{
		axios.post(process.env.VUE_APP_GENERATOR_API_ROOT + 'vouchers/delete', {
			voucherId: voucherId
		}, {
			headers: {
				Authorization: 'Token ' + store.state.apiToken
			}
		}).then((response) => {
			if (response.data.success === false)
			{
				throw new Error(response.data.message);
			}

			store.commit(mutations.voucherDeleted, voucherId);
		});
	}
}

export default AdminService;