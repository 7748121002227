
	import {Vue, Component} from 'vue-property-decorator';
	import {mapGetters} from 'vuex';
	import AdminService from "../../services/AdminService";
	import VoucherService from "../../services/VoucherService";

	@Component({
		name: 'Templates',
		computed: {
			...mapGetters([
				'voucherTemplates'
			])
		}
	})
	export default class Templates extends Vue
	{
		private adminService: AdminService;
		private voucherService: VoucherService;
		private voucherTemplates: Array<string>;
		private templateName: string = null;
		private formData: FormData;

		constructor()
		{
			super();

			this.adminService = new AdminService();
			this.voucherService = new VoucherService();
			this.formData = new FormData();
		}

		public deleteTemplate(templateId: number)
		{
			this.adminService.deleteVoucherTemplate(templateId).then((response) => {
				if (response.data.success)
				{
					this.voucherService.updateVoucherTemplates();
				}
			});
		}

		public fileChanged(files: FileList)
		{
			for (let i = 0; i < files.length; i++)
			{
				this.formData.append('templateFile', files[i], files[i].name);
			}
		}

		public submit()
		{
			this.formData.append('templateName', this.templateName);

			this.adminService.uploadVoucherTemplate(this.formData).then((response) => {
				if (response.data.success)
				{
					this.voucherService.updateVoucherTemplates();
				}
			});
		}
	}
