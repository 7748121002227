
	import {Vue, Component} from 'vue-property-decorator';
	import PaymentService from '../services/PaymentService';
	import {mapGetters} from 'vuex';
	import VoucherData from '../entities/VoucherData';
	// @ts-ignore
	import Sidebar from "../components/Sidebar";

	@Component({
		name: 'Payment',
		components: {Sidebar},
		computed: {
			...mapGetters([
				'voucherData',
				'voucherAmounts'
			])
		}
	})
	export default class Payment extends Vue
	{
		private paymentService: PaymentService;

		private voucherData: VoucherData;
		private voucherAmounts: Array<number>;

		constructor()
		{
			super();

			this.paymentService = new PaymentService();
		}

		initializePayment()
		{
			this.paymentService.initializePayment().then(() => {
				this.$router.push({name: 'closing'});
			});
		}
	}
