import Vue from 'vue';
import Vuex from 'vuex';
import mutations from './mutations';
import axios from 'axios';

import VoucherData from '@/entities/VoucherData';
import CustomerData from '@/entities/CustomerData';
import router from "@/router";
import OrderData from "@/entities/OrderData";
import VoucherFilter from "@/entities/VoucherFilter";

Vue.use(Vuex);

class InitialState
{
	public generatorApiRoot: string = process.env.VUE_APP_GENERATOR_API_ROOT;
	public orderInProgress: boolean = false;
	public voucherCompleted: boolean = false;
	public invoiceCompleted: boolean = false;
	public orderPlaced: boolean = false;
	public paymentInitialized: boolean = false;
	public paymentCompleted: boolean = false;
	public closingCompleted: boolean = false;
	public currentStepName: string = null;
	public voucherTemplates: object = null;
	public voucherAmounts: object = null;
	public voucherFilter: VoucherFilter = new VoucherFilter();
	public voucherData: VoucherData = new VoucherData();
	public customerData: CustomerData = new CustomerData();
	public orderData: OrderData = new OrderData();
	public customerId: number = null;
	public orderId: number = null;
	public voucherHash: string = null;
	public vouchers: Array<any> = null;
	public paymentToken: number = null;
	public apiToken: string = localStorage.getItem('apiToken');
	public loggedIn: boolean = !!localStorage.getItem('apiToken');
}

const store = new Vuex.Store({
	state: new InitialState(),
	getters: {
		voucherCompleted: (state) => {
			return state.voucherCompleted;
		},
		invoiceCompleted: (state) => {
			return state.invoiceCompleted;
		},
		orderPlaced: (state) => {
			return state.orderPlaced;
		},
		paymentInitialized: (state) => {
			return state.paymentInitialized;
		},
		paymentCompleted: (state) => {
			return state.paymentCompleted;
		},
		closingCompleted: (state) => {
			return state.closingCompleted;
		},
		voucherTemplates: (state) => {
			return state.voucherTemplates;
		},
		voucherAmounts: (state) => {
			return state.voucherAmounts;
		},
		voucherData: (state) => {
			return state.voucherData;
		},
		customerData: (state) => {
			return state.customerData;
		},
		orderData: (state) => {
			return state.orderData;
		},
		vouchers: (state) => {
			return state.vouchers;
		},
		voucherFilter: (state) => {
			return state.voucherFilter;
		}
	},
	mutations: {
		[mutations.startOrderProcess] (state) {
			state.orderInProgress = true;
		},
		[mutations.stopOrderProcess] (state) {
			state.orderInProgress = false;
		},
		[mutations.voucherTemplatesUpdated] (state, voucherTemplates) {
			state.voucherTemplates = voucherTemplates;
		},
		[mutations.voucherAmountsUpdated] (state, voucherAmounts) {
			state.voucherAmounts = voucherAmounts;
		},
		[mutations.voucherCompleted] (state, voucherData) {
			state.voucherCompleted = true;
			state.currentStepName = 'invoice';
			state.voucherData = voucherData;
		},
		[mutations.invoiceCompleted] (state, customerData) {
			state.invoiceCompleted = true;
			state.customerData = customerData;
			state.currentStepName = 'check-order';
		},
		[mutations.orderPlaced] (state, data) {
			state.orderPlaced = true;
			state.customerId = data.customerId;
			state.orderId = data.orderId;
			state.voucherHash = data.voucherHash;
			state.currentStepName = 'payment';
		},
		[mutations.paymentInitialized] (state, paymentToken) {
			state.paymentInitialized = true;
			state.paymentToken = paymentToken;
		},
		[mutations.paymentCompleted] (state) {
			state.paymentInitialized = false;
			state.paymentCompleted = true;
			state.currentStepName = 'closing';
		},
		[mutations.login] (state, data) {
			state.loggedIn = true;
			localStorage.setItem('apiToken', data.apiToken);
			state.apiToken = data.apiToken;
		},
		[mutations.logout] (state) {
			state.loggedIn = false;
			localStorage.removeItem('apiToken');
			state.apiToken = null;
		},
		[mutations.invalidateToken] (state) {
			state.loggedIn = false;
			localStorage.removeItem('apiToken');
			state.apiToken = null;
		},
		[mutations.vouchersUpdated] (state, vouchers) {
			state.vouchers = vouchers;
		},
		[mutations.singleVoucherUpdated] (state, voucher) {
			for (let i = 0; i < state.vouchers.length; ++i)
			{
				if (state.vouchers[i].id === voucher.id)
				{
					Vue.set(state.vouchers, i, voucher);
				}
			}
		},
		[mutations.voucherDeleted] (state, voucherId) {
			for (let i = 0; i < state.vouchers.length; ++i)
			{
				if (state.vouchers[i].id === voucherId)
				{
					Vue.delete(state.vouchers, i);
				}
			}
		},
		[mutations.resetState] (state) {
			let initialState = new InitialState();

			Object.keys(initialState).forEach(key => {
				//do not replace voucher templates and voucher amounts
				if (key !== 'voucherTemplates' && key !== 'voucherAmounts')
				{
					// @ts-ignore
					state[key] = initialState[key];
				}
			});
		}
	}
});

export default store;