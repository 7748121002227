import axios from 'axios';
import store from '@/store';
import mutations from "@/mutations";

class UserService
{
	public async login(username: string, password: string)
	{
		let response = await axios.post(process.env.VUE_APP_GENERATOR_API_ROOT + 'user/login', {
			username: username,
			password: password
		});

		if (!response.data.success || !response.data.apiToken)
		{
			throw new Error(response.data.message);
		}

		store.commit(mutations.login, {
			apiToken: response.data.apiToken
		});
	}

	public async logout()
	{
		let response = await axios.post(process.env.VUE_APP_GENERATOR_API_ROOT + 'user/logout', {
			apiToken: localStorage.getItem('apiToken')
		});

		if (!response.data.success)
		{
			throw new Error('Logout failed!');
		}

		store.commit(mutations.logout);
	}

	public getVoucherTemplates()
	{
		axios.get(process.env.VUE_APP_GENERATOR_API_ROOT + 'voucher/templates?token=' + store.state.apiToken);
	}
}

export default UserService;