class CustomerData
{
	public firstName: string = null;
	public lastName: string = null;
	public street: string = null;
	public email: string = null;
	public postalCode: number = null;
	public city: string = null;
}

export default CustomerData;