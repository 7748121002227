export enum PAYMENT_TYPES
{
	DEBIT_CARD = 1,
	CASH = 2
};

export enum PAYMENT_STATUS
{
	Initialized = 1,
	Success = 2,
	Aborted = 3,
	Error = 4
};