
	import {Vue, Component, Prop} from 'vue-property-decorator';
	import PaymentService from '@/services/PaymentService';

	@Component({
		name: 'PaymentSuccess'
	})
	export default class PaymentSuccess extends Vue
	{
		private paymentService: PaymentService;

		constructor()
		{
			super();

			this.paymentService = new PaymentService();
		}

		public mounted()
		{
			//@ts-ignore
			this.paymentService.getPaymentStatus(this.$route.params.orderId).then((response) => {
				window.close();
			});
		}
	}
