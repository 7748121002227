
	import {Vue, Component} from 'vue-property-decorator';
	import AdminService from "../../services/AdminService";

	@Component({
		name: 'Admin'
	})
	export default class Admin extends Vue
	{
		private adminService: AdminService;

		constructor()
		{
			super();

			this.adminService = new AdminService();
		}

		mounted()
		{
			this.adminService.checkApiToken();
		}
	}
