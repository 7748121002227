
	import {Vue, Component, Prop} from 'vue-property-decorator';
	import {mapGetters} from 'vuex';
	import AdminService from "../../services/AdminService";
	import VoucherFilter from "../../entities/VoucherFilter";
	import {PAYMENT_STATUS, PAYMENT_TYPES} from '@/enums';

	@Component({
		name: 'AdminVouchers',
		computed: {
			...mapGetters([
				'vouchers',
				'voucherTemplates',
				'voucherAmounts',
				'voucherFilter'
			])
		}
	})
	export default class AdminVouchers extends Vue
	{
		private adminService: AdminService;

		private PAYMENT_TYPES = PAYMENT_TYPES;

		private voucherTemplates: Array<object>;
		private voucherAmounts: Array<number>;
		private vouchers: Array<object>;
		private voucherFilter: VoucherFilter;

		constructor()
		{
			super();

			this.adminService = new AdminService();
		}

		public mounted()
		{
			this.adminService.getVouchers();
		}

		public redeemVoucher(voucherId: number)
		{
			this.adminService.redeemVoucher(voucherId);
		}

		public reactivateVoucher(voucherId: number)
		{
			this.adminService.reactivateVoucher(voucherId);
		}

		public deleteVoucher(voucherId: number)
		{
			if (confirm('Wollen Sie den Gutschein wirklich löschen?'))
			{
				this.adminService.deleteVoucher(voucherId);
			}
		}

		public get filteredVouchers()
		{
			let filteredVouchers = this.vouchers;

			if (this.voucherFilter.paymentStatus != 0)
			{
				filteredVouchers = filteredVouchers.filter((voucher: any) => {
					//not paid
					if (this.voucherFilter.paymentStatus == 1)
					{
						return !voucher.order.payment || voucher.order.payment.status !== PAYMENT_STATUS.Success;
					}

					//paid
					if (this.voucherFilter.paymentStatus == 2)
					{
						return voucher.order.payment && voucher.order.payment.status == PAYMENT_STATUS.Success;
					}
				});
			}

			if (this.voucherFilter.redeemedStatus != 0)
			{
				filteredVouchers = filteredVouchers.filter((voucher: any) => {
					if (this.voucherFilter.redeemedStatus == 1)
					{
						return voucher.redeemedAt === null;
					}

					if (this.voucherFilter.redeemedStatus == 2)
					{
						return voucher.redeemedAt !== null;
					}
				});
			}

			return filteredVouchers;
		}

		public setVoucherPaymentStatus(voucherId: number, paid: boolean)
		{
			this.adminService.setVoucherPaymentStatus(voucherId, paid);
		}

		public getDownloadLink(voucherHash?: string): string
		{
			if (!voucherHash)
			{
				return null;
			}

			return process.env.VUE_APP_GENERATOR_API_ROOT + 'vouchers/generate?voucherHash=' + voucherHash;
		}

		public formatDate(dateString: string)
		{
			if (!dateString)
			{
				return '-';
			}

			let date = new Date(dateString);

			return date.toLocaleDateString('de-DE', {
				day: '2-digit',
				year: 'numeric',
				month: '2-digit'
			});
		}
	}
