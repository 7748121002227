
	import {Component, Vue, Watch} from 'vue-property-decorator';
	import {mapGetters} from 'vuex';
	import {required} from "vuelidate/lib/validators";

	import VoucherData from '@/entities/VoucherData';
	import VoucherService from '@/services/VoucherService';
	// @ts-ignore
	import Sidebar from "../components/Sidebar";
	import mutations from '@/mutations';

	@Component({
		name: 'Voucher',
		components: {Sidebar},
		computed: mapGetters([
			'voucherData',
			'voucherTemplates',
			'voucherAmounts'
		]),
		validations: {
			voucherData: {
				template: {
					required
				},
				amount: {
					required
				},
				firstName: {
					required
				},
				lastName: {
					required
				}
			}
		}
	})
	export default class Voucher extends Vue
	{
		private voucherService: VoucherService;
		private voucherData: VoucherData;
		private voucherTemplates: Array<string>;
		private voucherAmounts: Array<number>;

		private messageCharacterCount: number = 0;
		private maxMessageCharacters: number = 150;

		constructor()
		{
			super();

			this.voucherService = new VoucherService();
		}

		public mounted()
		{
			this.voucherService.updateVoucherTemplates();
			this.voucherService.updateVoucherAmounts();

			if (this.voucherData.message)
			{
				this.messageCharacterCount = this.voucherData.message.length;
			}

			this.$store.commit(mutations.startOrderProcess);
		}

		public templateSelected(templateId: number)
		{
			this.voucherData.template = templateId;

			//@ts-ignore, needed because vuelidate has buggy typescript support
			this.$v.voucherData.template.$touch();
		}

		@Watch('voucherData.message')
		public messageChanged(newMessage: string, oldMessage: string)
		{
			this.messageCharacterCount = newMessage.length;
		}

		public submit()
		{
			this.$v.$touch();

			if (!this.$v.$invalid)
			{
				this.voucherService.saveVoucher(this.voucherData);
				this.$router.push('invoice');
			}
		}
	}
