
	import {Vue, Component} from 'vue-property-decorator';
	import UserService from "../../services/UserService";

	@Component({
		name: 'Logout'
	})
	export default class Logout extends Vue
	{
		private userService: UserService;

		private username: string = null;
		private password: string = null;

		constructor()
		{
			super();

			this.userService = new UserService();
		}

		created()
		{
			this.userService.logout().then(() => {
				this.$router.push('/');
			}).catch((e) => {
				console.log(e);
				this.$router.back();
			});
		}
	}
