
	import {Vue, Component} from 'vue-property-decorator';
	// @ts-ignore
	import Sidebar from "../components/Sidebar";
	import {mapGetters} from 'vuex';
	import OrderData from "../entities/OrderData";
	import {PAYMENT_TYPES, PAYMENT_STATUS} from '../enums';
	import VoucherService from '@/services/VoucherService';
	import PaymentService from '@/services/PaymentService';
	import mutations from '@/mutations';

	@Component({
		name: "Closing",
		components: {Sidebar},
		computed: {
			...mapGetters([
				'orderData'
			])
		}
	})
	export default class Closing extends Vue
	{
		private PAYMENT_TYPES = PAYMENT_TYPES;
		private PAYMENT_STATUS = PAYMENT_STATUS;

		private paymentService: PaymentService;
		private voucherService: VoucherService;
		private orderData: OrderData;
		private paymentStatus: number = null;
		private pollStatusInterval: number;
		private downloadLink: string = null;

		constructor()
		{
			super();

			this.paymentService = new PaymentService();
			this.voucherService = new VoucherService();
		}

		public mounted()
		{
			if (this.orderData.paymentType === PAYMENT_TYPES.DEBIT_CARD)
			{
				if (this.pollStatusInterval)
				{
					clearInterval(this.pollStatusInterval);
				}

				this.pollStatusInterval = window.setInterval(() => {
					this.paymentService.getPaymentStatus().then((response) => {
						if (response.data.success)
						{
							this.paymentStatus = response.data.paymentStatus;

							if (response.data.paymentStatus !== PAYMENT_STATUS.Initialized)
							{
								clearInterval(this.pollStatusInterval);
							}

							if (response.data.paymentStatus === PAYMENT_STATUS.Success)
							{
								this.downloadLink = process.env.VUE_APP_GENERATOR_API_ROOT + 'vouchers/generate?voucherHash=' + this.$store.state.voucherHash;

								this.$store.commit(mutations.paymentCompleted);
							}
						}
					});
				}, 5000);
			}
		}

		public beforeDestroy()
		{
			clearInterval(this.pollStatusInterval);
		}
	}
