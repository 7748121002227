
	import {Vue, Component} from 'vue-property-decorator';
	import UserService from "../../services/UserService";

	@Component({
		name: 'Login'
	})
	export default class Login extends Vue
	{
		private userService: UserService;

		private username: string = null;
		private password: string = null;
		private alertText: string = null;

		constructor()
		{
			super();

			this.userService = new UserService();
		}

		public submit()
		{
			this.userService.login(this.username, this.password).then((response) => {
				this.$router.push('/admin/vouchers');
			}).catch((e) => {
				this.alertText = 'Benutzername oder Passwort falsch!'
			});
		}
	}
