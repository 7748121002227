import store from '@/store';
import mutations from '@/mutations';
import axios from 'axios';
import VoucherData from "@/entities/VoucherData";
import CustomerData from '@/entities/CustomerData';
import {PAYMENT_TYPES} from "@/enums";
import router from '@/router';

class VoucherService
{
	public saveVoucher(voucher: VoucherData)
	{
		if (!store.getters.orderPlaced)
		{
			store.commit(mutations.voucherCompleted, voucher);
		}
	}

	public saveInvoice(invoiceData: CustomerData)
	{
		if (!store.getters.orderPlaced)
		{
			store.commit(mutations.invoiceCompleted, invoiceData);
		}
	}

	public updateVoucherTemplates()
	{
		axios.get(process.env.VUE_APP_GENERATOR_API_ROOT + 'vouchers/templates').then((response) => {
			if (response.data.success)
			{
				store.commit(mutations.voucherTemplatesUpdated, response.data.templates);
			}
			else
			{
				throw new Error(response.data.message);
			}
		}).catch((e) => {
			console.log(e);
		});
	}

	public updateVoucherAmounts()
	{
		axios.get(process.env.VUE_APP_GENERATOR_API_ROOT + 'vouchers/amounts').then((response) => {
			if (response.data.success)
			{
				store.commit(mutations.voucherAmountsUpdated, response.data.voucherAmounts);
			}
		});
	}

	public async getPaymentTypes()
	{
		return await axios.get(process.env.VUE_APP_GENERATOR_API_ROOT + 'payment/get-payment-types');
	}

	public async submitData()
	{
		return await axios.post(process.env.VUE_APP_GENERATOR_API_ROOT + 'order/create', {
			voucherData: store.getters.voucherData,
			customerData: store.getters.customerData,
			orderData: store.getters.orderData
		}).then((response) => {
			if (response.data.success && response.data.customerId && response.data.orderId && response.data.voucherHash)
			{
				store.commit(mutations.orderPlaced, {
					customerId: response.data.customerId,
					orderId: response.data.orderId,
					voucherHash: response.data.voucherHash
				});

				//if payment cash is selected set payment completed so we can route to closing page
				if (store.getters.orderData.paymentType === PAYMENT_TYPES.CASH)
				{
					store.commit(mutations.paymentCompleted);
				}
			}
		}).catch((e) => {
			console.log(e);
		});
	}

	public async getVoucherDownloadLink()
	{
		return await axios.post(process.env.VUE_APP_GENERATOR_API_ROOT + 'vouchers/get-download-link', {
			orderId: store.state.orderId
		});
	}
}

export default VoucherService;