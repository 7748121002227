
	import {Component, Vue} from 'vue-property-decorator';
	import {mapGetters} from 'vuex';
	import {required, numeric, email} from 'vuelidate/lib/validators';

	import VoucherService from '@/services/VoucherService';
	import CustomerData from '@/entities/CustomerData';
	// @ts-ignore
	import Sidebar from "../components/Sidebar";

	@Component({
		name: 'Invoice',
		components: {Sidebar},
		computed: mapGetters([
			'customerData'
		]),
		validations: {
			customerData: {
				firstName: {
					required
				},
				lastName: {
					required
				},
				street: {
					required
				},
				email: {
					required,
					email
				},
				postalCode: {
					required,
					numeric
				},
				city: {
					required
				}
			}
		}
	})
	export default class Invoice extends Vue
	{
		private voucherService: VoucherService;

		private customerData: CustomerData;

		constructor()
		{
			super();

			this.voucherService = new VoucherService();
		}

		public submit()
		{
			this.$v.$touch();

			if (!this.$v.$invalid)
			{
				this.voucherService.saveInvoice(this.customerData);
				this.$router.push('check-order');
			}
		}
	}
