import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Vuelidate from 'vuelidate';
import './registerServiceWorker';
import './directives/tooltip';

import 'bootstrap';

import './assets/fontawesome.min.css';
import 'bootstrap/dist/css/bootstrap-reboot.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';

Vue.config.productionTip = false;

Vue.use(Vuelidate);

new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app');
