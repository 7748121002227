
	import {Component, Vue} from 'vue-property-decorator';
	import {mapGetters} from 'vuex';
	import {PAYMENT_TYPES} from '../enums';
	import VoucherData from "../entities/VoucherData";
	import CustomerData from "../entities/CustomerData";
	import VoucherService from "../services/VoucherService";
	import {required} from 'vuelidate/lib/validators';
	import {checked} from '../validators';

	// @ts-ignore
	import Sidebar from "../components/Sidebar";
	import OrderData from "../entities/OrderData";

	@Component({
		name: 'Payment',
		components: {Sidebar},
		computed: mapGetters([
			'voucherData',
			'customerData',
			'orderData',
			'voucherTemplates',
			'voucherAmounts'
		]),
		validations: {
			termsAccepted: {
				checked
			},
			orderData: {
				paymentType: {
					required
				}
			}
		}
	})
	export default class Payment extends Vue
	{
		private voucherService: VoucherService;

		private voucherTemplates: Array<string>;
		private voucherAmounts: Array<number>;
		private voucherData: VoucherData;
		private customerData: CustomerData;
		private orderData: OrderData;
		private termsAccepted: boolean = false;

		private paymentTypes: Array<number> = [];

		constructor()
		{
			super();

			this.voucherService = new VoucherService();
		}

		public mounted()
		{
			this.voucherService.getPaymentTypes().then((response) => {
				this.paymentTypes = response.data.paymentTypes;
			});
		}

		public placeOrder()
		{
			if (confirm('Sind Sie sicher?\n\nIhre Bestellung kann nicht mehr geändert werden!'))
			{
				this.voucherService.submitData().then(() => {
					switch (this.orderData.paymentType)
					{
						case PAYMENT_TYPES.DEBIT_CARD:
							this.$router.push({name: 'payment'});
						break;

						case PAYMENT_TYPES.CASH:
							this.$router.push({name: 'closing'});
						break;
					}
				});
			}
		}

		public submit()
		{
			this.$v.$touch();

			if (!this.$v.$invalid)
			{
				this.placeOrder();
			}
		}
	}
