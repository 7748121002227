
	import {Vue, Component} from 'vue-property-decorator';
	import VoucherService from "./services/VoucherService";

	@Component({
		name: 'App'
	})
	export default class App extends Vue
	{
		private voucherService: VoucherService;

		constructor()
		{
			super();

			this.voucherService = new VoucherService();
		}

		public mounted()
		{
			this.voucherService.updateVoucherTemplates();
			this.voucherService.updateVoucherAmounts();
		}
	}
