import Vue from 'vue';
import $ from 'jquery';

//directive for bootstrap tooltips
Vue.directive('tooltip', {
	inserted(el, binding) {
		$(el).tooltip({
			title: binding.value,
			trigger: 'hover'
		})
	},
	update(el, binding) {
		$(el).attr('data-original-title', binding.value); //update the tooltip title dynamically
	}
});