import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
import Voucher from './views/Voucher.vue';
import Invoice from './views/Invoice.vue';
import CheckOrder from './views/CheckOrder.vue';
import Payment from './views/Payment.vue';
import Closing from './views/Closing.vue';
import Templates from './views/admin/Templates.vue';
import Admin from './views/admin/Admin.vue';
import Login from './views/admin/Login.vue';
import Logout from './views/admin/Logout.vue';
import AdminVouchers from './views/admin/AdminVouchers.vue';
import PaymentSuccess from './views/PaymentSuccess.vue';
import PaymentFail from './views/PaymentFail.vue';
import Imprint from './views/Imprint.vue';
import {PAYMENT_TYPES} from "@/enums";
import store from './store';
import mutations from "@/mutations";

Vue.use(Router);

const router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		{
			path: '/',
			name: 'home',
			component: Home
		},
		{
			path: '/admin',
			name: 'admin',
			component: Admin,
			meta: {
				isAdminPage: true
			},
			beforeEnter(to, from, next) {
				if (!store.state.loggedIn && to.fullPath !== '/admin/login')
				{
					return next('/admin/login');
				}

				next();
			},
			children: [
				{
					path: 'login',
					name: 'login',
					component: Login,
					meta: {
						isLoginPage: true
					}
				},
				{
					path: 'logout',
					name: 'logout',
					component: Logout
				},
				{
					path: 'templates',
					name: 'templates',
					component: Templates,
					meta: {
						isAdminPage: true
					}
				},
				{
					path: 'vouchers',
					name: 'vouchers',
					component: AdminVouchers,
					meta: {
						isAdminPage: true
					}
				}
			]
		},
		{
			path: '/imprint',
			name: 'imprint',
			component: Imprint
		},
		{
			path: '/voucher',
			name: 'voucher',
			component: Voucher,
			beforeEnter(to, from, next) {
				store.commit(mutations.startOrderProcess);

				if (!store.getters.orderPlaced)
				{
					next();
				}
				else
				{
					//we need this so we can redirect to home page on page reload (otherwise we are stuck)
					if (store.state.orderInProgress)
					{
						next(false);
					}
					else
					{
						next({name: 'home'});
					}
				}
			}
		},
		{
			path: '/invoice',
			name: 'invoice',
			component: Invoice,
			beforeEnter(to, from, next) {
				if (store.state.voucherCompleted && !store.getters.orderPlaced)
				{
					next();
				}
				else
				{
					//we need this so we can redirect to home page on page reload (otherwise we are stuck)
					if (store.state.orderInProgress)
					{
						next(false);
					}
					else
					{
						next({name: 'home'});
					}
				}
			}
		},
		{
			path: '/check-order',
			name: 'check-order',
			component: CheckOrder,
			beforeEnter(to, from, next) {
				if (store.getters.invoiceCompleted && !store.getters.orderPlaced)
				{
					next();
				}
				else
				{
					//we need this so we can redirect to home page on page reload (otherwise we are stuck)
					if (store.state.orderInProgress)
					{
						next(false);
					}
					else
					{
						next({name: 'home'});
					}
				}
			}
		},
		{
			path: '/payment',
			name: 'payment',
			component: Payment,
			beforeEnter(to, from, next) {
				if (store.getters.orderPlaced && store.getters.orderData.paymentType !== PAYMENT_TYPES.CASH)
				{
					next();
				}
				else
				{
					//we need this so we can redirect to home page on page reload (otherwise we are stuck)
					if (store.state.orderInProgress)
					{
						next(false);
					}
					else
					{
						next({name: 'home'});
					}
				}
			}
		},
		{
			path: '/closing',
			name: 'closing',
			component: Closing,
			beforeEnter(to, from, next) {
				if (store.state.paymentInitialized || store.state.paymentCompleted)
				{
					next();
				}
				else
				{
					//we need this so we can redirect to home page on page reload (otherwise we are stuck)
					if (store.state.orderInProgress)
					{
						next(false);
					}
					else
					{
						next({name: 'home'});
					}
				}
			}
		},
		{
			path: '/payment/success/:orderId',
			name: 'paymentSuccess',
			component: PaymentSuccess
		},
		{
			path: '/payment/fail/:orderId',
			name: 'paymentFail',
			component: PaymentFail
		}
	]
});

export default router;